import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import axios from "axios";
import config from './config';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
   },
    color: '#ffffff',
    fontSize: '18px',
    height: '50px',
  },
  getCode: {
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
    },
    color: '#ffffff !important',
    fontSize: '16px',
    height: '52px',
    marginTop: "8px",
  }
}));

export default function WithdrawConfig({ phone, id }) {
  const classes = useStyles();

  const [alipayNo, setAlipayNo] = useState('');
  const [name, setName] = useState('');
  const [usdtAddress, setUsdtAddress] = useState('');
  const [code, setCode] = useState('');
  const [unit, setUnit] = React.useState('cny');
  const [sended, setSended] = useState(false);
  const [autoWithdraw, setAutoWithdraw] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const getUserAlipay = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getUserAlipay`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      if (ret.data.data) {
        setAlipayNo(ret.data.data.alipayNo || ret.data.data.phone);
        setName(ret.data.data.name || '');
        setUnit(ret.data.data.withdrawBaseUnit || 'cny');
        setUsdtAddress(ret.data.data.usdtAddress || '');
      } else {
        setAlipayNo('未设置');
        setName('未设置');
      }
    }
  }

  const reqSmsCode = async () => {
    if (phone === '00000000') {
      alert('网维未给该子账户设置手机号');
      return;
    }
    setSended(true);
    const ret = await axios.post(`${config.API}/v1/user/reqSmsCode`, {
      phone,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert(`验证码已发送到手机号 ${phone}`);
      return;
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      setSended(false);
      alert('手机号格式错误');
    } else {
      setSended(false);
      alert('未知错误');
    }
  }

  const withdrawAllBalance = async() => {
    const ret = await axios.get(`${config.API}/v1/aposimz/withdrawBalanceBatch?id=${id}`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert(`提现成功，提现账号数量：${ret.data.data.totalNumber}，总金额：${ret.data.data.totalCnyBalance}元`);
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      alert(`提现失败：${ret.data.data}`);
    } else {
      alert('提现失败：网络请求失败');
    }
  }

  const setUserAlipay = async () => {
    if (name.length > 10) {
      alert('姓名过长');
      return;
    }
    if (name.length === 0) {
      alert('未填姓名');
      return;
    }
    if (alipayNo.length > 80) {
      alert('支付宝账号过长');
      return;
    }
    if (code.length !== 6) {
      alert('验证码长度错误');
    }
    if (usdtAddress.length > 0) {
      if (usdtAddress.length !== 34 || !usdtAddress.startsWith('T')) {
        alert('USDT TRC20/TRX 钱包地址格式校验错误');
        return;
      }
    }
    if (unit === 'usd' && usdtAddress.length !== 34) {
      alert('选择 USDT 提现需要设置 USDT TRC20/TRX 钱包地址');
      return;
    }
    const ret = await axios.get(`${config.API}/v1/aposimz/setUserAlipay?name=${name}&alipayNo=${alipayNo}&code=${code}&usdtAddress=${usdtAddress}&withdrawBaseUnit=${unit}`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert('设置成功');
      window.location.reload();
    } else if (ret.data && ret.data.data === 'code fail') {
      alert('验证码错误');
    } else {
      alert(`设置失败：${ret.data.data}`);
    }
  }

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  }

  const handleAutoWithdraw = async () => {
    await axios.get(`${config.API}/v1/aposimz/setAutoDailyWithdraw?value=${checked}`);
    autoWithdrawClose();
    setAutoWithdraw(checked);
  }

  const getAutoWithdraw = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getAutoDailyWithdraw`, {
      params: {},
      withCredentials: true
    });
    setAutoWithdraw(ret.data.data.autoDailyWithdraw);
  } 

  const autoWithdrawOpen = (e) => {
    setDialogOpen(true);
    setChecked(e.target.checked);
  }

  const autoWithdrawClose = () => {
    setDialogOpen(false);
  }


  useEffect(() => {
    getUserAlipay();
    getAutoWithdraw();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        提现设置
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="支付宝账号（手机或者邮箱）"
          name="phone"
          autoComplete="phone"
          autoFocus
          value={alipayNo}
          onChange={(event) => {setAlipayNo(event.target.value)}}
          error={false}
          disabled={true}
          helperText=""
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="实名姓名"
          name="phone"
          autoComplete="phone"
          autoFocus
          value={name}
          onChange={(event) => {setName(event.target.value)}}
          error={false}
          disabled={true}
          helperText=""
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="usdtAddress"
          label="（选填）USDT TRC20/TRX 钱包地址"
          name="usdtAddress"
          autoFocus
          value={usdtAddress}
          onChange={(event) => {setUsdtAddress(event.target.value)}}
          error={!((usdtAddress.length === 34 && usdtAddress.startsWith('T')) || usdtAddress.length === 0)}
          helperText=""
        />
        <br/>
        <br/>
        <FormControl component="fieldset">
          <FormLabel component="legend">提现方式</FormLabel>
          <RadioGroup row aria-label="unit" name="unit" value={unit} onChange={handleUnitChange}>
            <FormControlLabel value="cny" control={<Radio />} label="支付宝" />
            <FormControlLabel value="usd" control={<Radio />} label="USDT (TRC20/TRX)" />
          </RadioGroup>
        </FormControl>
        <Grid
            container
          spacing={2}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="登录手机号的验证码"
              id="code"
              value={code}
              onChange={(event) => {setCode(event.target.value)}}
              error={false}
              helperText=""
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              className={classes.getCode}
              onClick={() => { reqSmsCode() }}
              disabled={sended}
            >
              {sended ? '已发送' : '获取验证码'}
            </Button>
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={() => { setUserAlipay() }}
        >
          提交
        </Button>
      </form>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * 请先联系管理员修改手机号、支付宝账号、实名姓名。
      </Typography>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * 设置好手机号后，用户可以自主设置 USDT 钱包地址，选择 USDT 提现或者支付宝提现。
      </Typography>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * 支付宝提现：已暂停。
      </Typography>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * USDT 提现：5分钟内到账，最低提现数量 20 USDT，提现零手续费。
      </Typography>
      <br/>
      <br/>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        定时提现
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={autoWithdraw}
            onChange={autoWithdrawOpen}
            name="autoWithdraw"
            color="primary"
          />
        }
        label="开启定时提现"
      />
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * 定时提现：将在每天上午9点（UTC+8）自动根据当前设置的提现渠道，自动申请提现。
      </Typography>
      <Dialog
        open={dialogOpen}
        onClose={autoWithdrawClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {autoWithdraw ? 
          '定时提现' 
          : '定时提现'
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {autoWithdraw ? 
          '点击确认，将关闭定时提现。' 
          : '点击确认，将在每天上午9点（UTC+8）自动根据当前设置的提现渠道，申请提现。'
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={autoWithdrawClose} color="primary">
            取消
          </Button>
          <Button onClick={handleAutoWithdraw} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <br/>
      <br/>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        批量功能
      </Typography>
      <Button className={classes.submit} variant="contained" onClick={withdrawAllBalance} color="primary" size="large" style={{marginLeft: '8px', width: '160px'}}>
        全部提现
      </Button>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * 全部提现：使用该支付宝实名制的所有账号申请提现。
      </Typography>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        * 如果有账户设置了使用 USDT 提现，则会自动走 USDT 渠道。 
      </Typography>
    </React.Fragment>
  );
}