import { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import lightBlue from '@material-ui/core/colors/lightBlue';
import axios from "axios";
import config from './config';

let once = false;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: lightBlue[600],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
   },
    color: '#ffffff',
    fontSize: '18px',
    height: '50px',
  },
  getCode: {
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
    },
    color: '#ffffff !important',
    fontSize: '16px',
    height: '52px',
    marginTop: "8px",
  }
}));

export default function SignIn({ loginCallback }) {
  const classes = useStyles();
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const loginSubByPassword = async () => {
    const ret = await axios.get(`${config.API}/v1/user/loginSubByPassword?id=${id}&password=${password}`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      loginCallback(ret.data.id, '00000000');
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      alert(ret.data.data);
    } else {
      alert('未知错误');
    }
  }

  const checkBrowser = () => {
    const issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if (issafariBrowser && !once) {
      alert('推荐使用 UC 浏览器保持登入状态');
      once = true;
    }
  }

  useEffect(() => {
    checkBrowser();
    // eslint-disable-next-line
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          网吧版后台
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="id"
            label="ID"
            name="id"
            autoComplete="id"
            autoFocus
            value={id}
            onChange={(event) => {setId(event.target.value)}}
            error={false}
            helperText=""
          />
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="密码"
                id="password"
                value={password}
                onChange={(event) => {setPassword(event.target.value)}}
                error={false}
                helperText=""
              />
            </Grid>
          </Grid>
          
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={() => { loginSubByPassword() }}
          >
            登录
          </Button>
        </form>
      </div>
    </Container>
  );
}