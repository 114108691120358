import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DesktopWindows from '@material-ui/icons/DesktopWindows';
import Speed from '@material-ui/icons/Speed';
import AccessTime from '@material-ui/icons/AccessTime';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircle from '@material-ui/icons/RemoveCircleOutline'
import Timelapse from '@material-ui/icons/Timelapse'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '0px',
    marginBottom: '20px',
    fontSize: '1.25rem',
    color: "#3f51b5"
  }
}));

export default function CurrentWorker({ name, data }) {
  const classes = useStyles();

  const [worker, setWorker] = useState(0);
  const [hashrate, setHashrate] = useState(0);
  const [lastUpdate, setLastUpdate] = useState('暂无');
  const [unit, setUnit] = useState('MH/s');
  const [invalidRate, setInvalidRate] = useState(-1);
  const [staleRate, setStaleRate] = useState(-1);

  useEffect(() => {
    if (data && data[name] && data[name].length > 0) {
      const detail = data[name][0];
      setWorker(detail.workerCount || 0);
      setHashrate(detail.hashrate.toFixed(2) || 0);
      const last = new Date(detail.date);
      const gap = Date.now() - last;
      if (gap < 1000) {
        setLastUpdate('刚刚');
      } else if (gap < 60000) {
        setLastUpdate(`${(gap / 1000).toFixed(0)} 秒前`);
      } else if (gap < 3600000) {
        setLastUpdate(`${(gap / 60000).toFixed(0)} 分钟前`);
      } else if (gap < 86400000) {
        setLastUpdate(`${(gap / 86400000).toFixed(0)} 天前`);
      }
      switch(name) {
        case 'etc':
        case 'rvn':
        case 'erg':
        case 'cfx':
        case 'kas':
          setUnit('MH/s');
          setInvalidRate((detail.invalidRate * 100).toFixed(2));
          setStaleRate((detail.staleRate * 100).toFixed(2));
          break;
          break;
        case 'xmr':
          setHashrate((detail.hashrate / 1000).toFixed(2) || 0);
          setUnit('KH/s');
          break;
        default:
          setUnit('MH/s');
          break;
      }
    }
  }, [name, data]);

  return (
    <React.Fragment>
      <p className={classes.title}>{name.toUpperCase()}数据{name === 'xmr' ? ' (CPU)' : ''}</p>
      <Typography component="p" variant="body1">
        <DesktopWindows fontSize="small"></DesktopWindows>
        {` 在线机器: ${worker} 台`}
      </Typography>
      <Typography component="p" variant="body1">
        <Speed fontSize="small"></Speed>
        {` 实时算力: ${hashrate} ${unit}`}
        <Tooltip title="机器数量少于10台的实时算力和图表算力非常不准确！！！ 请以4小时以上的平均图表算力为准">
          <IconButton aria-label="info" size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
      {invalidRate > -1 &&
      <Typography component="p" variant="body1">
        <RemoveCircle fontSize="small"></RemoveCircle>
        {` 无效份额: ${invalidRate}%`}
        <Tooltip title="无效份额是机器提交了错误的计算结果，如果大于 1% 说明机器有问题，可能是超频过度或者 CPU 双挖导致的计算错误">
          <IconButton aria-label="info" size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
      }
      {staleRate > -1 &&
      <Typography component="p" variant="body1">
        <Timelapse fontSize="small"></Timelapse>
        {` 过期份额: ${staleRate}%`}
        <Tooltip title="过期份额是机器延迟过大提交了过期的结果，如果大于 2% 说明机器网络延迟较大，请优化网络和路由器">
          <IconButton aria-label="info" size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
      }
      <Typography component="p" variant="body1">
        <AccessTime fontSize="small"></AccessTime>
        {` 更新时间: ${lastUpdate}`}
      </Typography>
    </React.Fragment>
  );
}
