import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  paginationStyle: {
    margin: '25px 10px 5px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  subtitle: {
    display: 'flex',
    width: '100%',
    padding: '5px 2px 15px 15px'
  },
  showBox: {
    display: 'flex',
    width: '100%',
    fontSize: '15px',
    justifyContent: 'flex-start',
    fontWeight: '500',
    alignItems: 'center',
    lineHeight: '30px',
    color: '#404040'
  },
  showBox2: {
    display: 'flex',
    width: '100%',
    fontSize: '15px',
    justifyContent: 'flex-start',
    fontWeight: '500',
    alignItems: 'center',
    lineHeight: '30px',
    color: '#404040'
  },
  showBoxFigure: {
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '30px',
    color: 'black',
  },
  figure: {
    fontWeight: '600'
  }
});

export default function WithdrawList({data, count, statsRaw, pageLimit, getWithdrawHistory, id, unit}) {

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [stats, setStats] = useState({});

  const pageNum = Math.ceil(count / pageLimit);

  const onChange = (event, page) => {
    getWithdrawHistory(id, page - 1, pageLimit);
  }

  useEffect(() => {
    const temp = [];
    for (let index = 0; index < data.length; index++) {
      const tempDate = new Date(data[index].date);
      temp.push({
        id: index,
        date: `${tempDate.getFullYear()}年${tempDate.getMonth() + 1}月${tempDate.getDate()}日${tempDate.getHours()}点`,
        withdrawId: data[index].withdrawId,
        usdtTxId: data[index].usdtTxId,
        btc: (data[index].btc || 0) / 1e8,
        cny: data[index].cny,
        usd: data[index].usd,
        unit: data[index].baseUnit,
        isFinish: data[index].isFinish ? '已完成' : (data[index].statusCode === 101 ? '支付宝提现账户不存在，修改后自动重试' : '等待中'),
      });
    }
    setRows(temp);
    setStats({
      cnySumCurr: (statsRaw.cnySumCurr || 0).toFixed(2),
      cnySumPrev: (statsRaw.cnySumPrev || 0).toFixed(2),
      cnySum: (statsRaw.cnySum || 0).toFixed(2),
      usdSumCurr: (statsRaw.usdSumCurr || 0).toFixed(2),
      usdSumPrev: (statsRaw.usdSumPrev || 0).toFixed(2),
      usdSum: (statsRaw.usdSum || 0).toFixed(2),
    });
  }, [data, statsRaw]);

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        提现记录
      </Typography>
      <div className={classes.subtitle}>
        <Grid container>
          <Grid item xs={12} lg={3}>
            <div className={classes.showBox2}>
              { (new Date(Date.now())).getMonth() + 1}月总提现 
              <div className={classes.showBoxFigure}>
                <span className={classes.figure}>{stats.cnySumCurr}</span> 元,
                $<span className={classes.figure}>{stats.usdSumCurr}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <div className={classes.showBox2}>
              { (new Date(Date.now())).getMonth() }月总提现 
              <div className={classes.showBoxFigure}>
                <span className={classes.figure}>{stats.cnySumPrev}</span> 元,
                $<span className={classes.figure}>{stats.usdSumPrev}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <div className={classes.showBox2}>
              历史总提现 
              <div className={classes.showBoxFigure}>
                <span className={classes.figure}>{stats.cnySum}</span> 元,
                $<span className={classes.figure}>{stats.usdSum}</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日期</TableCell>
            <TableCell>提现ID</TableCell>
            <TableCell>BTC</TableCell>
            <TableCell>金额</TableCell>
            <TableCell>单位</TableCell>
            <TableCell>状态</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              {
              row.usdtTxId ?
              <TableCell><a href={'https://tronscan.org/#/transaction/' + row.usdtTxId} target="_blank" rel="noreferrer">{row.usdtTxId.substr(0, 12) + '...'}</a></TableCell>
              :
              <TableCell>{row.withdrawId}</TableCell>
              }
              <TableCell>{row.btc}</TableCell>
              {row.unit === 'cny' && (
                <TableCell>{row.cny} 元</TableCell>
                )}
              {row.unit !== 'cny' && <TableCell>{'$' + row.usd}</TableCell>}
              <TableCell>{row.unit}</TableCell>
              <TableCell>{row.isFinish}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination className={classes.paginationStyle} count={pageNum} onChange={onChange} />
    </React.Fragment>
  );
}