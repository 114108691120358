import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BarChartIcon from '@material-ui/icons/BarChart';
import ListIcon from '@material-ui/icons/List';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Divider from '@material-ui/core/Divider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subHeader: {
    fontFamily: "Noto Sans SC",
    fontSize: "16px",
  },
  listItem: {
    fontFamily: "Noto Sans SC",
    fontSize: "16px",
  }
}));

export function MainListItems({ menuCallback }) {

  const classes = useStyles();

  return (
    <div>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('dashboard')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        数据面板
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('workers')}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        矿机列表
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('withdrawConfig')}>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        提现设置
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('transfer')}>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        站内转赠
      </ListItem>
      <p/>
      <Divider />
    </div>
  );
}
