import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import lightBlue from '@material-ui/core/colors/lightBlue';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { MainListItems } from './listItems';
import Chart from './dashboard/Chart';
import Price from './dashboard/Price';
import Balance from './dashboard/Balance';
import CurrentWorker from './dashboard/CurrentWorker';
import DailyIncome from './dashboard/DailyIncome';
import SignIn from './SignIn';
import axios from "axios";
import config from './config';
import WithdrawList from './dashboard/WithdrawList';
import ReferralTotal from './dashboard/ReferralTotal';
import ReferralDaily from './dashboard/ReferralDaily';
import WithdrawConfig from './WithdrawConfig';
import RemoteSetting from './RemoteSetting';
import Workers from './Workers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Transfer from './Transfer';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: lightBlue[600],
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    height: '48px',
  },
  logoText: {
    fontFamily: "Noto Sans SC",
    fontSize: '18px',
    padding: '4px',
    color: lightBlue[700],
    fontWeight: 500,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightChart: {
    height: 400,
  }
}));

export default function HomeLayout() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);

  const [stateLogin, setStateLogin] = React.useState(true);
  const [stateDashboard, setStateDashboard] = React.useState(false);
  const [stateWorkers, setStateWorkers] = React.useState(false);
  const [stateWithdrawConfig, setStateWithdrawConfig] = React.useState(false);
  const [stateTransfer, setStateTransfer] = React.useState(false);
  const [stateRemoteSetting, setStateRemoteSetting] = React.useState(false);

  const [id, setId] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [comment, setComment] = React.useState('');
  const [currentWorker, setCurrentWorker] = React.useState({});
  const [dailyIncome, setDailyIncome] = React.useState([]);
  const [dailyIncomeCount, setDailyIncomeCount] = React.useState(20);
  const [dailyIncomeStats, setDailyIncomeStats] = React.useState({});
  const [withdrawList, setWithdrawList] = React.useState([]);
  const [withdrawListCount, setWithdrawListCount] = React.useState(0);
  const [withdrawListStats, setWithdrawListStats] = React.useState({});
  const [workerStatus, setWorkerStatus] = React.useState({});
  const [price, setPrice] = React.useState(null);
  const [referralTotalBalance, setReferralTotalBalance] = React.useState(null);
  const [referralBalanceHistory, setReferralBalanceHistory] = React.useState(null);
  const [withdrawBaseUnit, setWithdrawBaseUnit] = React.useState('usd');
  const [openMessage, setOpenMessage] = React.useState(false);

  const pageLimit = 20;

  const switchState = (name) => {
    setStateLogin(false);
    setStateDashboard(false);
    setStateWorkers(false);
    setStateWithdrawConfig(false);
    setStateTransfer(false);
    setStateRemoteSetting(false);
    switch (name) {
      case 'login': {
        setStateLogin(true);
        break;
      }
      case 'dashboard': {
        setStateDashboard(true);
        break;
      }
      case 'workers': {
        setStateWorkers(true);
        break;
      }
      case 'withdrawConfig': {
        setStateWithdrawConfig(true);
        break;
      }
      case 'transfer': {
        setStateTransfer(true);
        break;
      }
      case 'remoteSetting': {
        setStateRemoteSetting(true);
        break;
      }
      default:
        break;
    }
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  }

  const handleCloseMessage = () => {
    setOpenMessage(false);
  }

  const checkLoginState = async () => {
    const ret = await axios.get(`${config.API}/v1/user/checkLoginState`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      const id = ret.data.id;
      const phone = ret.data.phone;
      const unit = ret.data.withdrawBaseUnit || 'usd';
      localStorage.setItem('id', id);
      localStorage.setItem('phone', phone);
      setId(id);
      setPhone(phone);
      switchState('dashboard');
      getReferralTotalBalance(id);
      getCurrentWorker(id);
      getDailyIncome(id, 0, pageLimit);
      getWorkerStatus(id);
      getWithdrawHistory(id, 0, pageLimit);
      setWithdrawBaseUnit(unit);
      window.transfer.unit = unit;
      setOpenMessage(true);
      const ret2 = await axios.get(`${config.API}/v1/aposimz/getSubRelation?subId=${id}`, {
        withCredentials: true,
      });
      if (ret2.status === 200 && ret2.data && ret2.data.success && ret2.data.data) {
        setComment(ret2.data.data.comment || '');
      }
    } else {
      switchState('login');
    }
  }

  const logout = async () => {
    await axios.get(`${config.API}/v1/user/logout`, {
      withCredentials: true,
    });
    setId(null);
    setPhone(null);
    localStorage.removeItem('id');
    localStorage.removeItem('phone');
    switchState('login');
  }

  const loginCallback = async (id, phone) => {
    setId(id);
    setPhone(phone);
    localStorage.setItem('id', id);
    localStorage.setItem('phone', phone);
    switchState('dashboard');
    getReferralTotalBalance(id);
    getCurrentWorker(id);
    getDailyIncome(id, 0, pageLimit);
    getWorkerStatus(id);
    getWithdrawHistory(id, 0, pageLimit);
  }

  const menuCallback = (name) => {
    if (!phone) {
      return;
    }
    switchState(name);
  }

  const getCurrentWorker = async (id) => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getCurrentWorkerStatus?id=${id}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setCurrentWorker(ret.data.data);
    }
  }

  const getDailyIncome = async (id, page, limit) => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getDailyIncome?id=${id}&page=${page}&limit=${limit}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setDailyIncome(ret.data.data.dailyIncome || []);
      setDailyIncomeCount(ret.data.data.count || 0);
      setDailyIncomeStats({ 
        btcSumCurr: ret.data.btcSumCurr || 0.00,
        btcSumPrev: ret.data.btcSumPrev || 0.00,
        graphDataCurr: ret.data.graphDataCurr || [{ angle: 1 }],
        graphDataPrev: ret.data.graphDataPrev || [{ angle: 1 }],
        btcSum: ret.data.btcSum || 0.00
      });
    }
  }

  const getWorkerStatus = async (id) => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getDayWorkerStatus?id=${id}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setWorkerStatus(ret.data.data || []);
    }
  }

  const getPrice = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getCryptocurrencyPrice`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setPrice(ret.data.data);
      window.transfer.btc2cny = parseFloat(ret.data.data.btc2cny.toFixed(2));
      window.transfer.btc2usd = parseFloat(ret.data.data.btc2usd.toFixed(2));
    }
  }

  const getWithdrawHistory = async (id, page, limit) => {
    await getPrice();
    const ret = await axios.get(`${config.API}/v1/aposimz/getWithdrawBalanceHistory?id=${id}&page=${page}&limit=${limit}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setWithdrawList(ret.data.data.withdrawHistory || []);
      setWithdrawListCount(ret.data.data.count || 0);
      setWithdrawListStats({ 
        cnySumCurr: ret.data.data.cnySumCurr || 0.00,
        cnySumPrev: ret.data.data.cnySumPrev || 0.00,
        cnySum: ret.data.data.cnySum || 0.00,
        usdSumCurr: ret.data.data.usdSumCurr || 0.00,
        usdSumPrev: ret.data.data.usdSumPrev || 0.00,
        usdSum: ret.data.data.usdSum || 0.00,
      });
    }
  }

  const getReferralTotalBalance = async (id) => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getReferralTotalBalance?id=${id}`);
    if (ret.status === 200 && ret.data && ret.data.success && ret.data.data.length > 0) {
      setReferralTotalBalance(ret.data.data || []);
      const ret2 = await axios.get(`${config.API}/v1/aposimz/getReferralBalanceHistory?id=${id}&day=3`);
      if (ret2.status === 200 && ret2.data && ret2.data.success) {
        setReferralBalanceHistory(ret2.data.data.dailyReferral || []);
      }
    }
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixedHeightChart = clsx(classes.paper, classes.fixedHeightChart);

  useEffect(() => {
    window.transfer = {}; // 全局传递转递转赠数据
    if (window.innerWidth < 1200) {
      setOpen(false);
    }
    checkLoginState();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
          <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {comment}
          </Typography>

          {phone && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openProfile}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>{phone}</MenuItem>
                <MenuItem onClick={handleClose}>{id}</MenuItem>
                <MenuItem onClick={handleLogout}>登出</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Typography variant="subtitle1" className={classes.logoText}>网吧版后台</Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems menuCallback={menuCallback}/>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {
            stateLogin &&
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <SignIn loginCallback={loginCallback}></SignIn>
              </Grid>
            </Grid>
          }
          {
            stateDashboard &&
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <Balance id={id} unit={withdrawBaseUnit} />
                </Paper>
              </Grid>
              { price &&
              <Grid item xs={12} md={6} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <Price data={price} unit={withdrawBaseUnit} />
                </Paper>
              </Grid>
              }
              <Grid item xs={12} md={6} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <CurrentWorker name={'etc'} data={currentWorker}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <CurrentWorker name={'cfx'} data={currentWorker}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <CurrentWorker name={'xmr'} data={currentWorker}/>
                </Paper>
              </Grid>
              {workerStatus['etc'] && workerStatus['etc'].length > 0 &&
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightChart}>
                  <Chart id={id} name={'etc'} data={workerStatus}/>
                </Paper>
              </Grid>}
              {workerStatus['cfx'] && workerStatus['cfx'].length > 0 &&
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightChart}>
                  <Chart id={id} name={'cfx'} data={workerStatus}/>
                </Paper>
              </Grid>}
              {workerStatus['xmr'] && workerStatus['xmr'].length > 0 &&
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightChart}>
                  <Chart id={id} name={'xmr'} data={workerStatus}/>
                </Paper>
              </Grid>}
              {referralTotalBalance && price &&
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <ReferralTotal data={referralTotalBalance} btc2cny={price ? price.btc2cny : 0}/>
                </Paper>
              </Grid>
              }
              {referralBalanceHistory && price &&
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <ReferralDaily data={referralBalanceHistory} btc2cny={price ? price.btc2cny : 0}/>
                </Paper>
              </Grid>
              }
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <DailyIncome price={price || {}} data={dailyIncome} count={dailyIncomeCount} statsRaw={dailyIncomeStats} pageLimit={pageLimit} getDailyIncome={getDailyIncome} id={id} unit={withdrawBaseUnit} btc2cny={price ? price.btc2cny : 0} btc2usd={price ? price.btc2usd : 0}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <WithdrawList data={withdrawList} count={withdrawListCount} statsRaw={withdrawListStats} pageLimit={pageLimit} getWithdrawHistory={getWithdrawHistory} id={id} unit={withdrawBaseUnit}/>
                </Paper>
              </Grid>
            </Grid>
          }
          {
            stateWorkers &&
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Workers id={id}></Workers>
                </Paper>
              </Grid>
            </Grid>
          }
          {
            stateWithdrawConfig &&
            <Grid container spacing={0}>
              <Grid item xs={12} md={8} lg={6}>
                <Paper className={classes.paper}>
                  <WithdrawConfig phone={phone} id={id}></WithdrawConfig>
                </Paper>
              </Grid>
            </Grid>
          }
          {
            stateTransfer &&
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Transfer phone={phone} id={id}></Transfer>
                </Paper>
              </Grid>
            </Grid>
          }
          {
            stateRemoteSetting &&
            <Grid container spacing={0}>
              <Grid item xs={12} md={8} lg={6}>
                <Paper className={classes.paper}>
                  <RemoteSetting id={id}></RemoteSetting>
                </Paper>
              </Grid>
            </Grid>
          }
        </Container>
        <Dialog onClose={handleCloseMessage} aria-labelledby="customized-dialog-title" open={openMessage}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseMessage}>
            公告 3月5日
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              1. v2.0 新版支持 40 系列显卡挖 CFX 和 ETC。<br/>
              2. 部分地区如果无法登入成功获取短信，可以使用工具翻墙再点击获取短信。<br/>
              3. 网页系统目前在香港，如果访问不畅通请使用工具。<br/>
              4. 上述部分地区客户端上报算力失败并不影响算力和收益，收益以图表算力为准。<br/>
              <br/>
              温馨提示：<br/>
              * 站内转赠现在需要<b>手机验证码</b>。<br/>
              * <a href="https://www.yuque.com/skypool/uox3k7/wqgr0x" target="_blank" rel="noreferrer">点击查看 USDT 提现变现教程</a>，有任何问题可在群里咨询。<br/>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseMessage} color="primary">
              知道了
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
}