import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import axios from "axios";
import config from './config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
   },
    color: '#ffffff',
    fontSize: '18px',
    height: '50px',
  },
  getCode: {
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
    },
    color: '#ffffff !important',
    fontSize: '16px',
    height: '52px',
    marginTop: "8px",
  },
  deposit: {
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
    },
    color: '#ffffff !important',
    fontSize: '16px',
    height: '32px',
    marginTop: "0px",
    marginLeft: "0px",
    marginBottom: "8px",
  }
}));

export default function Transfer({ phone, id }) {
  const classes = useStyles();
  const pageLimit = 20;

  const [transferSKYPID, setTransferSKYPID] = useState('SKYP');
  const [btcValue, setBtcValue] = useState(0);
  const [full, setFull] = useState(false);
  const [transferCheck, setTransferCheck] = useState(false);

  const [transferTable, setTransferTable] = React.useState([]);
  const [transferTableCount, setTransferTableCount] = React.useState(0);

  const [depositList, setDepositList] = useState(false);
  const [depositTable, setDepositTable] = useState([]);

  const [sended, setSended] = useState(false);
  const [code, setCode] = useState('');

  const fullBalance = () => {
    setFull(true);
    setBtcValue(parseFloat(window.transfer.balanceBTC / 1e8).toFixed(8));
  }
  
  const handleTransferCheckOpen = () => {
    setTransferCheck(true);
  };

  const handleTransferCheckClose = () => {
    setTransferCheck(false);
  };

  const transferClick = async () => {
    setTransferCheck(false);
    if (code.length !== 6) {
      alert('请输入6位验证码');
      return;
    }
    var ret;
    var inId = transferSKYPID;
    var btcAmount = Math.floor(parseFloat(btcValue) * 1e8);
    if (full) {
      ret = await axios.get(`${config.API}/v1/internalTransfer/internalTransfer?inId=${inId}&code=${code}`, { withCredentials: true });
    } else {
      ret = await axios.get(`${config.API}/v1/internalTransfer/internalTransfer?inId=${inId}&btcAmount=${btcAmount}&code=${code}`, { withCredentials: true });
    }
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert('站内转赠成功');
      setTimeout(() => {
        getInternalTransferHistory(id, 0, pageLimit);
      }, 500);
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      alert(`转赠失败：${ret.data.data}`);
    } else {
      alert('转赠失败：网络请求失败');
    }
  }

  const getInternalTransferHistory = async (id, page, limit) => {
    const ret = await axios.get(`${config.API}/v1/internalTransfer/getInternalTransferHistory?id=${id}&page=${page}&limit=${limit}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setTransferTable(ret.data.data.internalTransferHistory || []);
      setTransferTableCount(ret.data.data.count || 0);
    }
  }

  const onChange = (event, page) => {
    getInternalTransferHistory(id, page - 1, pageLimit);
  }

  const openDepositList = async () => {
    setDepositList(true);
    const ret = await axios.get(`${config.API}/v1/internalTransfer/getAllDeposit`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setDepositTable(ret.data.deposit || []);
    }
  }

  const closeDepositList = () => {
    setDepositList(false);
  }

  const reqSmsCode = async () => {
    setSended(true);
    const ret = await axios.post(`${config.API}/v1/user/reqSmsCode`, {
      phone,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert(`验证码已发送到手机号 ${phone}`);
      return;
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      setSended(false);
      alert('手机号格式错误');
    } else {
      setSended(false);
      alert('未知错误');
    }
  }


  useEffect(() => {
    // eslint-disable-next-line
    getInternalTransferHistory(id, 0, pageLimit);
  }, []);

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        站内转赠
      </Typography>
      <Typography component="p" variant="caption" className={classes.depositContext}>
        <Button
          size="small"
          variant="outlined"
          className={classes.deposit}
          onClick={() => { openDepositList() }}
        >
          商户押金
        </Button>
        <br/>
        * 请确保转给的是你认识熟悉的人，谨防骗币，可先查看商户押金<br/>
        * 站内转赠立刻到账，无手续费
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="skypid"
          label="站内收款人 SKYP 开头的 ID"
          name="skypid"
          autoComplete="skypid"
          autoFocus
          value={transferSKYPID}
          onChange={(event) => {setTransferSKYPID(event.target.value)}}
          error={!((transferSKYPID.length === 15 && transferSKYPID.startsWith('SKYP')) || transferSKYPID.length === 0)}
          helperText=""
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="btcValue"
              label="转赠 BTC 金额"
              name="btcValue"
              autoComplete="btcValue"
              autoFocus
              value={btcValue}
              onChange={(event) => {
                if (!isNaN(event.target.value)) {
                  setBtcValue(event.target.value);
                  setFull(false);
                }
              }}
              error={parseFloat(btcValue) > parseFloat(window.transfer.balanceBTC / 1e8)}
              helperText=""
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              className={classes.getCode}
              onClick={() => { fullBalance() }}
            >
              全部余额
            </Button>
          </Grid>
        </Grid>
        <Typography component="p" variant="body1">
          ≈ {(parseFloat(btcValue) * window.transfer.btc2cny).toFixed(2) + '元'} ≈ {'$' + (parseFloat(btcValue) * window.transfer.btc2usd).toFixed(2) }
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="登录手机号的验证码"
              id="code"
              value={code}
              onChange={(event) => {setCode(event.target.value)}}
              error={false}
              helperText=""
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              className={classes.getCode}
              onClick={() => { reqSmsCode() }}
              disabled={sended}
            >
              {sended ? '已发送' : '获取验证码'}
            </Button>
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={() => {
            if (!(transferSKYPID.length === 15 && transferSKYPID.startsWith('SKYP'))) {
              alert('SKYP ID 格式错误');
              return;
            }
            if (transferSKYPID === id) {
              alert('不能转给自己');
              return;
            }
            if (!full && parseFloat(btcValue) > parseFloat(window.transfer.balanceBTC / 1e8)) {
              alert('转出金额大于余额');
              return;
            }
            handleTransferCheckOpen();
          }}
        >
          转赠
        </Button>
      </form>
      <Dialog
        open={transferCheck}
        onClose={handleTransferCheckClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">站内转赠确认</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {'请务必确认转给的是认识的人和正确的 SKYP ID。'}
          <br/>
          {' 一旦确认，就会从余额扣除。'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTransferCheckClose} color="primary">
            取消
          </Button>
          <Button onClick={transferClick} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <br/>
      <br/>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        转赠记录
      </Typography>
      <Typography component="p" variant="caption" className={classes.depositContext}>
      * CNY 和 USDT 为转赠发生时刻的 BTC 价格换算。
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日期</TableCell>
            <TableCell>转赠ID</TableCell>
            <TableCell>付款人</TableCell>
            <TableCell>收款人</TableCell>
            <TableCell>BTC</TableCell>
            <TableCell>CNY</TableCell>
            <TableCell>USDT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transferTable.map((row) => (
            <TableRow key={row.transferId}>
              <TableCell>{`${new Date(row.date).getFullYear()}年${new Date(row.date).getMonth() + 1}月${new Date(row.date).getDate()}日${new Date(row.date).getHours()}点`}</TableCell>
              <TableCell>{row.transferId}</TableCell>
            {
              id === row.outId ?
              <TableCell><b>{row.outId}</b></TableCell>:
              <TableCell>{row.outId}</TableCell>
            }
            {
              id === row.inId ?
              <TableCell><b>{row.inId}</b></TableCell>:
              <TableCell>{row.inId}</TableCell>
            }
              <TableCell>{(row.btcAmount / 1e8).toFixed(8)}</TableCell>
              <TableCell>{row.cnyAmount || 0} 元</TableCell>
              <TableCell>${row.usdAmount || 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination className={classes.paginationStyle} count={ Math.ceil(transferTableCount / pageLimit)} onChange={onChange} />

      <Dialog
        open={depositList}
        onClose={closeDepositList}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">站内押金</DialogTitle>
        <DialogContent>
          <Typography component="p" variant="caption" className={classes.depositContext}>
            * <a href="https://www.yuque.com/skypool/uox3k7/uen5lk" target="_blank" rel="noreferrer">商户押金充值教程</a><br/>
            * 以下列表为平台押金1000元以上的商户公示，方便站内信用交易，交易风险自行把握
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>押金</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {depositTable.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.cny || 0} 元</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDepositList} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>
      
    </React.Fragment>
  );
}
