import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import lightBlue from '@material-ui/core/colors/lightBlue';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: grey[900],
  },
  text: {
    color: grey[100],
  },
  link: {
    color: lightBlue[200],
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        {/* <Typography variant="body2" className={classes.text}>
          沪ICP备18001040号-1
        </Typography> */}
      </Container>
    </footer>
  );
}