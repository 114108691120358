import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from './Footer';
import HomeLayout from './HomeLayout';
import axios from 'axios';
axios.defaults.withCredentials = true;

function App() {
  return (
    <div>
      <CssBaseline />
      <HomeLayout />
      <Footer/>
    </div>
  );
}

export default App;
