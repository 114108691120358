import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from "axios";
import config from './config';


export default function RemoteSetting({ id }) {
  const [forbidMining, setForbidMining] = useState(false);

  const getUserConfig = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getUserConfig?id=${id}&noCache=1`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setForbidMining(ret.data.data.forbidMining || false);
    }
  }

  const setForbidMiningConfig = async (value) => {
    const sendValue = value ? 1 : 0;
    await axios.get(`${config.API}/v1/aposimz/setForbidMiningConfig?value=${sendValue}`, { withCredentials: true });
  }

  const handleForbidMining = (event) => {
    setForbidMining(event.target.checked);
    setForbidMiningConfig(event.target.checked);
  };

  useEffect(() => {
    getUserConfig(id);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        远程群控
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={forbidMining}
            onChange={handleForbidMining}
            name="forbidMining"
            color="primary"
          />
        }
        label="远程关闭所有矿机"
      />
    </React.Fragment>
  );
}