import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function Message({open, setOpen, text, severity}) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
      <MuiAlert severity={severity} elevation={6} variant="filled" onClose={() => setOpen(false)}>
        { text }
      </MuiAlert>
    </Snackbar>
  )
}