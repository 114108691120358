import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import lightBlue from '@material-ui/core/colors/lightBlue';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from "axios";
import config from './config';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: lightBlue[600],
  },
  statsBox: {
    padding: '15px 10px 10px'
  },
  statsBoxItem: {
    fontSize: '15px'
  },
  figure: {
    fontWeight: '600'
  },
  additionalLine: {
    fontSize: '13px',
    padding: '5px 10px 15px',
    color: '#565656'
  }
}));

export default function WithdrawConfig({id}) {
  const classes = useStyles();

  const [onlineLabel, setOnlineLabel] = useState('在线矿机');
  const [offlineLabel, setOfflineLabel] = useState('掉线矿机');
  const [hideOnlineLabel, setHideOnlineLabel] = useState('在线矿机');
  const [hideOfflineLabel, setHideOfflineLabel] = useState('在线矿机');
  const [value, setValue] = useState(0);
  const [online, setOnline] = useState([]);
  const [offline, setOffline] = useState([]);
  const [hideOnline, setHideOnline] = useState([]);
  const [hideOffline, setHideOffline] = useState([]);
  const [hideZero, setHideZero] = useState(true);
  const [coinHashMap, setCoinHashMap] = useState(new Map());

  const coinTypeToUnit = (coinType) => {
    switch(coinType) {
      case 'etc':
      case 'rvn':
      case 'erg':
      case 'cfx':
      case 'kas':
        return ' MH/s';
      case 'xmr':
        return ' KH/s';
      default:
        return ' MH/s';
    }
  }

  const parseHashrate = (coinType, hashrate) => {
    if (coinType === 'xmr') {
      return (hashrate / 1000).toFixed(2);
    }
    return hashrate.toFixed(2);
  }

  const getCurrentWorkerStatusDetail = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getCurrentWorkerStatusDetail?id=${id}`);
    const on = [];
    const hideOn = [];
    const off = [];
    const hideOff = [];
    const now = Date.now();
    const coinHashMap = new Map();
    const addStats = (v) => {
      if (!coinHashMap.has(v.coinType)) {
        coinHashMap.set(v.coinType, v.hashrate);
      } else {
        const prevSum = coinHashMap.get(v.coinType);
        coinHashMap.set(v.coinType, prevSum + v.hashrate);
      }
    }
    if (ret.status === 200 && ret.data && ret.data.success) {
      // eslint-disable-next-line
      ret.data.data.map(v => {
        const t = {};
        t.worker = v.worker;
        t.coinType = v.coinType;
        t.hashrate = parseHashrate(v.coinType, v.hashrate) + coinTypeToUnit(v.coinType);
        t.temp = v.temperature || '暂无';
        const gap = now - new Date(v.lastDate);
        if (gap < 0) {
          t.lastupdate = '刚刚';
          on.push(t);
          addStats(v);
          if (parseFloat(v.hashrate) > 0) {
            hideOn.push(t);
          } 
        } else if (gap < 6e5) {
          if (gap < 6e4) {
            t.lastupdate = Math.round(gap / 1e3) + '秒前';
          } else {
            t.lastupdate = Math.round(gap / 6e4) + '分钟前';
          }
          on.push(t);
          addStats(v);
          if (parseFloat(v.hashrate) > 0) {
            hideOn.push(t);
          } 
        } else {
          if (gap < 36e5) {
            t.lastupdate = Math.round(gap / 6e4) + '分钟前';
          } else {
            t.lastupdate = Math.round(gap / 36e5) + '小时前';
          }
          off.push(t);
          if (parseFloat(v.hashrate) > 0) {
            hideOff.push(t);
          }
        }
      });
      setCoinHashMap(coinHashMap);
      setOnlineLabel(`在线矿机 (${on.length}台)`);
      setOfflineLabel(`掉线矿机 (${off.length}台)`);
      setOnline(on);
      setOffline(off);
      setHideOnlineLabel(`在线矿机 (${hideOn.length}台)`);
      setHideOfflineLabel(`掉线矿机 (${hideOff.length}台)`);
      setHideOnline(hideOn);
      setHideOffline(hideOff);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleHideZero = (event) => {
    setHideZero(event.target.checked);
  }

  useEffect(() => {
    getCurrentWorkerStatusDetail();
    // eslint-disable-next-line
  }, []);

  const coinNames = Array.from(coinHashMap.keys())

  return (
    <React.Fragment>
      <AppBar position="static">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          centered
        >
          {!hideZero && <Tab label={onlineLabel}/>}
          {!hideZero && <Tab label={offlineLabel}/>}
          {hideZero && <Tab label={hideOnlineLabel}/>}
          {hideZero && <Tab label={hideOfflineLabel}/>}
        </Tabs>
      </AppBar>
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={hideZero}
            onChange={handleHideZero}
            name="hideZero"
            color="primary"
          />
        }
        label="隐藏零算力机器"
      />
      {value === 0 && (
        <div>
        <Grid className={classes.statsBox} container>
          <Grid className={classes.statsBoxItem} item xs={12} sm={3}>
            总算力：
          </Grid>
          { coinNames.map((name) => {
              const hashrate = coinHashMap.get(name);
              return (
                <Grid className={classes.statsBoxItem} item xs={12} sm={3}>
                  { name }{`: `} 
                  <span className={classes.figure}>
                    { parseHashrate(name, hashrate) }
                  </span> 
                  { coinTypeToUnit(name) }
                </Grid>
              )
            }) }
        </Grid>
        <div className={classes.additionalLine}>
          * 矿机列表总算力为所有本地算力总和，和矿池计算出的算力有小幅度区别属于正常情况。
          如果有大幅度区别，请先排查矿机本地日志。
        </div>
        </div>
      )}
      {value === 0 &&
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>机器名</TableCell>
            <TableCell>币种</TableCell>
            <TableCell>算力</TableCell>
            <TableCell>温度</TableCell>
            <TableCell>最后提交</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!hideZero && online.map((row) => (
            <TableRow key={`${row.worker}:${row.coinType}`}>
              <TableCell>{row.worker}</TableCell>
              <TableCell>{row.coinType}</TableCell>
              <TableCell>{row.hashrate}</TableCell>
              <TableCell>{row.temp}</TableCell>
              <TableCell>{row.lastupdate}</TableCell>
            </TableRow>
          ))}
          {hideZero && hideOnline.map((row) => (
            <TableRow key={`${row.worker}:${row.coinType}`}>
              <TableCell>{row.worker}</TableCell>
              <TableCell>{row.coinType}</TableCell>
              <TableCell>{row.hashrate}</TableCell>
              <TableCell>{row.temp}</TableCell>
              <TableCell>{row.lastupdate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      }
      {value === 1 &&
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>机器名</TableCell>
            <TableCell>币种</TableCell>
            <TableCell>算力</TableCell>
            <TableCell>温度</TableCell>
            <TableCell>最后提交</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!hideZero && offline.map((row) => (
            <TableRow key={`${row.worker}:${row.coinType}`}>
              <TableCell>{row.worker}</TableCell>
              <TableCell>{row.coinType}</TableCell>
              <TableCell>{row.hashrate}</TableCell>
              <TableCell>{row.temp}</TableCell>
              <TableCell>{row.lastupdate}</TableCell>
            </TableRow>
          ))}
          {hideZero && hideOffline.map((row) => (
            <TableRow key={`${row.worker}:${row.coinType}`}>
              <TableCell>{row.worker}</TableCell>
              <TableCell>{row.coinType}</TableCell>
              <TableCell>{row.hashrate}</TableCell>
              <TableCell>{row.temp}</TableCell>
              <TableCell>{row.lastupdate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      }  
    </React.Fragment>
  );
}